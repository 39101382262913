import PageWrapper from "../components/PageWrapper";
import "../assets/map/LayeredUKMap-v2.scss"
import Benefits from "../sections/broadband/Benefits";
import Seo from "./../components/Seo";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../utils/helperFn";
import React, { useEffect, useContext } from "react";
import {Container, Row, Col} from "react-bootstrap";
import {Title, Section, Text} from "../components/Core";
import CheckAvailability from "../sections/index/CheckAvailability";
import BroadbandRegions from "../sections/index/BroadbandRegions";
import GlobalContext from "../context/GlobalContext";
import hero from "./../assets/image/rebrand/broadband-hero.jpg";
import styled from "styled-components";

const GradientSection = styled(Section)`
    position: relative;
    background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
    padding-bottom: 100px;
`;

const WhiteBox = styled.div`
    padding: 50px;
    border-radius: 30px;
    background-color: #fff;
    max-width: 500px;
    margin: auto;
`
const HeroSection = styled(Section)`
    position: relative;
    padding-top: 100px;
    padding-bottom: 0;
    @media (max-width: 800px) { 
        img {
            width: auto !important;
            height: 320px !important;
        }
    }
`

const AbsoluteTitle = styled(Title)`
    position: absolute;
    color: #fff;
    top: 25%;
    left: 15px;
    @media (min-width: 576px) { 
        top: 29%;
        left: calc((100vw - 525px) / 2);
    }
    @media (min-width: 768px) { 
        top: 24%;
        left: calc((100vw - 705px) / 2);
    }
    @media (min-width: 992px) { 
        top: 19%;
        left: calc((100vw - 945px) / 2);
    }

    @media (min-width: 1200px) {
        top: 25%;
        left: calc((100vw - 1125px) / 2);
    }
`;

const StyledText = styled(Text)`
    position: absolute;
    color: #fff;
    top: 55%;
    max-width: 550px;
    left: 15px;
    font-size: 22px;
    line-height: 1.1;
    @media (min-width: 576px) { 
        line-height: 1.5;
        top: 60%;
        left: calc((100vw - 525px) / 2);
        font-size: 28px;
    }
    @media (min-width: 768px) { 
        top: 55%;
        left: calc((100vw - 705px) / 2);
    }
    @media (min-width: 992px) { 
        top: 48%;
        left: calc((100vw - 945px) / 2);
    }

    @media (min-width: 1200px) {
        top: 50%;
        left: calc((100vw - 1125px) / 2);
    }
`;

const Broadband = () => {

    const gContext = useContext(GlobalContext)
    useEffect(() => {
        gContext.goResetRegistrationData()
    }, [])

    const data = useStaticQuery(graphql`
        query BroadbandQuery {
            strapiBroadbandPage {
                hero_title
                hero_subtitle
                benefits_title
                benefits_subtitle
                benefit_1_title
                benefit_1_subtitle
                benefit_1_icon {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
                benefit_2_title
                benefit_2_subtitle
                benefit_2_icon {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
                benefit_3_title
                benefit_3_subtitle
                benefit_3_icon {
                    childImageSharp {
                        original {
                            src
                        }
                    }
                }
                network_title
                network_subtitle
                network_subtitle_1
                check_availability_title
                check_availability_subtitle
            }
            strapiPageHeadings(slug: {eq: "broadband"}) {
                h1
                h21
                h22
                h3
            }
        }`
    );

    const pageData = getProperty(data, 'strapiBroadbandPage');
    const headingsData = getProperty(data, 'strapiPageHeadings');

    return (
        <>
            <Seo page="broadband"/>
            <PageWrapper>
                <HeroSection>
                    <img src={hero} alt="hero" className="w-100"/>
                    <AbsoluteTitle dangerouslySetInnerHTML={{ __html: headingsData.h1 }} />
                    <StyledText dangerouslySetInnerHTML={{ __html: pageData.hero_subtitle }} />
                </HeroSection>
                <Benefits data={pageData} headingsData={headingsData}/>
                <BroadbandRegions />
                <GradientSection>
                    <Container>
                        <Row>
                            <Col lg="8" className="text-center centered">
                                <Title className="white-color">
                                    Are you ready for
                                    ultrafast full fibre?
                                </Title>
                                <Text color="white">
                                    Use our broadband availability checker to find out
                                    whether we’ve connected your area. If your property's
                                    connection is ready, you can order service through our
                                    partner internet service providers.
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <WhiteBox className="mt-5">
                                    <Row>
                                        <Col lg="9" className="text-center centered">
                                            <Text color="black" lineHeight={1.2}>
                                                Enter your postcode to get<br />faster fibre today!
                                            </Text>
                                        </Col>
                                    </Row>
                                    <CheckAvailability
                                        focusInput={false}
                                        hideLogo={true}
                                        customTitle={""}
                                        customSubtitle={""}
                                    />
                                </WhiteBox>
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>
            </PageWrapper>
        </>
    );
};
export default Broadband;
