import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Title, Section, Text} from "../../components/Core";
import futureIcon from './../../assets/image/rebrand/future-icon.svg'
import reliableIcon from './../../assets/image/rebrand/reliable-icon.svg'
import ultrafastIcon from './../../assets/image/rebrand/ultrafast-icon.svg'
import styled from "styled-components";

const FeatureTitle = styled(Text)`
    font-size: 1.3em;
    color:black;
    font-weight: bold;
    margin-bottom: 20px;
`;

const FeatureContent = styled(Text)`
    line-height: 1.75;
    font-size: 1.2em;
`;

const PinkSection = styled(Section)`
    background-color: #fdf5f9;
`

const Benefits = (props) => {

    return (
        <PinkSection>
            <Container>
                <Row>
                    <Col lg="10" className="centered text-center">
                        <Title>{props.headingsData.h21} </Title>
                        <Text>{props.data.benefits_subtitle}</Text>
                    </Col>
                    <Col lg="12" className="mt-5 pt-5">
                        <Row>
                           <Col lg="4" className="text-center">
                                <img src={ultrafastIcon} alt="" height={60} className="mb-3"/>
                                <FeatureTitle>{props.data.benefit_1_title}</FeatureTitle>
                                <FeatureContent>{props.data.benefit_1_subtitle}</FeatureContent>
                            </Col>
                            <Col lg="4" className="text-center">
                                <img src={reliableIcon} alt="" height={60} className="mb-3"/>
                                <FeatureTitle>{props.data.benefit_2_title}</FeatureTitle>
                                <FeatureContent>{props.data.benefit_2_subtitle}</FeatureContent>
                            </Col>
                            <Col lg="4" className="text-center">
                                <img src={futureIcon} alt="" height={60} className="mb-3"/>
                                <FeatureTitle>{props.data.benefit_3_title}</FeatureTitle>
                                <FeatureContent>{props.data.benefit_3_subtitle}</FeatureContent>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </PinkSection>
    )
}



export default Benefits;
